// src/components/FileUpload/FileUpload.js

import React, { useState } from 'react';
import './FileUpload.css';

// function FileUpload({ onUploadSuccess, username }) {
function FileUpload({ onAnalyseClick, selectedNewRecording, setSelectedNewRecording }) {


  const handleFileChange = (e) => {
    const selectedFileUpload = e.target.files[0];
    setSelectedNewRecording(selectedFileUpload); // Only update the state
  };

  const handleAnalyseClick = (e) => {
    e.preventDefault();
    if (!selectedNewRecording) {
      alert('Please select a file to upload.');
      return;
    }
    onAnalyseClick(selectedNewRecording); // Notify parent component with the selected file
  };

  return (
    <div className="form-file">
      <form className="file-upload-form">
        <label className="custom-file-upload">
          Choose File (audio/video)
          <input
            type="file"
            accept=".mp4,.wav,.mp3,.m4a"
            onChange={handleFileChange}
          />
        </label>

        <button
          type="button"
          disabled={!selectedNewRecording}
          onClick={handleAnalyseClick}
        >
          Analyse
        </button>
      </form>
      {selectedNewRecording && 
      <p className="selected-file-name">
        Selected file: {selectedNewRecording.name}
      </p>}
    </div>
  );
}

export default FileUpload;
