// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import supabase from '../../supabaseClient';

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [authenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    const checkSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (session) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkSession();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // You can replace this with a spinner or loader
  }

  if (!authenticated) {
    return <Navigate to="/" replace />; // Redirect to Landing page if not authenticated
  }

  return children;
};

export default ProtectedRoute;
